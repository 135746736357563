import axios, { resHandle } from "@/plugins/axios";

/**
 * @description 获取最新上线古籍
 * @param vCom Vue组件
 */
const GetNewsBook = (vCom: any, loading?: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.get("api/knowledge/book/head").then(({ data }) => {
            resHandle(vCom, data, loading).then(() => {
                resolve(data.data);
            }).catch((err) => {
                if (loading) {
                    loading.close();
                }
                reject(err);
            });
        }).catch((err) => {
            if (loading) {
                loading.close();
            }
            reject(err);
        });
    });
};
export { GetNewsBook }