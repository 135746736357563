


































































































































import { Component, Vue, Prop } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import { quanxianMixin } from "@/mixins/quanxianMixin";
import MainHeader from "@/components/main-header/main-header.vue";
import { GetNewsBook } from "@/request/home";
import { clearFilter } from "@/utils/base";
@Component({
  components: {
    MainHeader,
  },
})
export default class Name extends mixins(quanxianMixin) {
  private search: any = "";
  private cTab: any = "";
  private jibings: any = [
    {
      label: "中医内科",
      children: ["咳嗽", "感冒", "痛风", "消渴", "中风", "高血压"],
    },
    {
      label: "中医骨伤科",
      children: ["骨折", "关节炎", "肩周炎", "骨质疏松", "颈椎病", "落枕"],
    },
    {
      label: "中医妇产科",
      children: ["月经不调", "痛经", "不孕", "盆腔疾病", "崩漏", "卵巢疾病"],
    },
    {
      label: "中医儿科",
      children: ["肺炎", "食积", "便秘", "哮喘", "水痘", "麻疹"],
    },
  ];
  private biaoqianData: any = [
    "新冠肺炎",
    "茯苓",
    "胃癌",
    "高血压",
    "哮喘",
    "黄芪",
    "干姜",
    "山楂",
    "神农本草经",
    "本草纲目",
    "支气管炎",
    "人参",
    "冠心病",
    "糖尿病",
    "李时珍",
    "橘皮",
    "黄连",
  ];
  private shujiList: any = [];
  private get AllKu() {
    return this.$store.state.kuList;
  }
  private get kuList() {
    const arr: any = [
      "西医疾病",
      "中医疾病",
      "症状体征",
      "中医证候",
      "中医治则",
      "中医治法",
      "中药材",
      "中成药",
      "方剂",
      "古籍",
    ];
    let data: any = [];
    arr.forEach((ele: any) => {
      this.$store.state.kuList.forEach((item: any) => {
        if (item.table_name == ele) {
          data.push(item);
        }
      });
    });
    return data;
  }
  private next() {
    const W = (this.$refs.shuWrap as any).offsetWidth;
    const maxL = -W + 132 * 6;
    const ele: any = document.getElementById("shuWrap");
    if (!ele.style.left) {
      ele.style.left = "-132px";
    } else if (Number(ele.style.left.split("px")[0]) < maxL + 10) {
      this.$message.warning("已经到底了");
      return;
    } else {
      const left: any = Number(ele.style.left.split("px")[0]);
      ele.style.left = left - 132 + "px";
    }
  }
  private before() {
    const W = (this.$refs.shuWrap as any).offsetWidth;
    const maxL = -W + 132 * 6;
    const ele: any = document.getElementById("shuWrap");
    if (!ele.style.left || Number(ele.style.left.split("px")[0]) > -10) {
      this.$message.warning("已经到底了");
    } else {
      const left: any = Number(ele.style.left.split("px")[0]);
      ele.style.left = left + 132 + "px";
    }
  }
  private goRead(item: any) {
    if (!this.getIsShowQuanxian("古籍库搜索")) {
      this.$message.warning("暂无权限！");
      return;
    }
    this.$store.commit("updateCurrentKu", { table_name: "古籍" });
    this.$router.push({
      path: "/main/storehouse/mulu",
      query: {
        id: item.book_id,
      },
    });
  }
  private getNewBook() {
    const loading = this.$loading({
      lock: true,
      text: "加载中……",
      spinner: "el-icon-loading",
      customClass: "loadClass",
      background: "rgba(255, 255, 255, 0.7)",
    });
    GetNewsBook(this, loading)
      .then((res: any) => {
        loading.close();
        this.shujiList = res;
      })
      .catch(() => {
        loading.close();
      });
  }
  private goZhishiku(val: any) {
    return;
    if (!this.getIsShowQuanxian(val.table_name + "库搜索")) {
      this.$message.warning("暂无权限！");
      return;
    }
    clearFilter();
    this.$store.commit("updateCurrentKu", val.table_name);
    this.$router.push("/main/storehouse/folder");
  }
  private isCanSearch() {
    return (
      this.getIsShowQuanxian("西医疾病库搜索") ||
      this.getIsShowQuanxian("中医疾病库搜索") ||
      this.getIsShowQuanxian("症状体征库搜索") ||
      this.getIsShowQuanxian("中医证候库搜索") ||
      this.getIsShowQuanxian("中医治则库搜索") ||
      this.getIsShowQuanxian("中医治法库搜索") ||
      this.getIsShowQuanxian("中药材库搜索") ||
      this.getIsShowQuanxian("中成药库搜索") ||
      this.getIsShowQuanxian("方剂库搜索") ||
      this.getIsShowQuanxian("古籍库搜索")
    );
  }
  private goSearch() {
    if (!this.isCanSearch()) {
      this.$message.warning("暂无权限");
      return;
    }
    if (!this.search) {
      this.$message.warning("请输入搜索关键词");
      return;
    }
    const d: any = {
      searchText: this.search,
      source: "首页",
    };
    localStorage.setItem("searchFilter", "");
    this.$store.commit("updateCurrentKu", this.cTab);
    this.$router.push({
      path: "/main/storehouse/search",
      query: {
        data: JSON.stringify(d),
      },
    });
  }
  private fastSearch(val: any) {
    if (!this.isCanSearch()) {
      this.$message.warning("暂无权限");
      return;
    }
    const d: any = {
      searchText: val,
      source: "首页",
    };
    localStorage.setItem("searchFilter", "");
    this.$store.commit("updateCurrentKu", "");
    this.$router.push({
      path: "/main/storehouse/search",
      query: {
        data: JSON.stringify(d),
      },
    });
  }
  mounted() {
    this.getNewBook();
  }
}
